<div nz-row nzJustify="center">
    <div nz-col nzXs="24" nzLg="18">
        <div nz-row nzJustify="center" *ngIf="!isSalesman">
            <div nz-col>
                <h5>
                    <br> Need Help ? just click the <b>Contact Me </b> Button below and a salesman will contact you.
                </h5>
            </div>
        </div>
        <div nz-row nzJustify="center" *ngIf="!isSalesman">
            <div nz-col>
                <button nz-button class="btn-mobile" (click)="contactSalesman()">
                    <span>
                        <i nz-icon nzType="phone" nzTheme="outline"></i> Contact me
                    </span>
                </button>
            </div>
        </div>
        <div nz-row nzJustify="center" *ngIf="posquote.comment">
            <div nz-col nzSpan="24" style="margin-top: 20px; margin-bottom: 10px;">
                <div style="background-color: lightgrey; padding-top: 10px; padding-bottom: 10px; padding-left: 30px; padding-right: 30px; border-radius: 50px; display: inline-block;">
                    <h4 style="display:inline; ">
                        {{posquote.comment}}
                    </h4>
                </div>
            </div>
        </div>
        <br />



        <!--Hiding Book a timeslot for now -->
        <!-- <span *ngIf="showAccepted && posquote.bookingsEnabled && !this.isSalesman" >
          <div nz-row nzJustify="center" >
              <div nz-col nzSpan="24"  >
                <button nz-button (click)="acceptQuote()" class="btn-mobile" [class]="'btnBack'">
                  <span>
                     <i nz-icon nzType="calendar" nzTheme="outline"></i>
                    Book a Time Slot
                  </span>
                </button>
            </div>
          </div>

          <br>
        </span> -->
        <h1>{{isMobile}}</h1>
   
        <ng-template *ngIf="isMobile then Mobile; else Other "></ng-template>

        <ng-template #Mobile>
            
            <nz-card class="sfText" style="margin-bottom: 25px;" nzBordered="false" *ngFor="let optionId of filteredLines(); let i = index" nzTitle="Option {{i + 1}} ">
                <div nz-row nzJustify="center">
                    <div nz-col nzSpan="24">
                        <img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{filteredItemsForOption(optionId)[0].brandLogo}}" class="brandImageMobile">
                    </div>
                </div>
                <br>
                <div nz-row nzJustify="center">
                    <div nz-col nzSpan="24">
                        <nz-table [nzLoading]="isLoading" [nzOuterBordered]="true" nzSize="small" #filteredOption [nzData]="filteredItemsForOption(optionId)" [nzHideOnSinglePage]="true">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Price</th>
                                    <th>Qty</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let line of filteredOption.data">
                                <tr style="font-size:12px ;">
                                    <td>{{line.description}}</td>
                                    <td>{{line.price |number }}</td>
                                    <td>
                                        <nz-input-number class="input-number " [ngModel]="line.qty" [nzMin]="1" [nzMax]="500" [nzStep]="1" (ngModelChange)="updateLineItem(i,$event)">
                                        </nz-input-number>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="this.posquote.custId == 14041 || this.posquote.custId == 0 then showloaderMobile; else showLinePriceMobile">
                                        </ng-container>
                                        <ng-template #showLinePriceMobile>
                                            {{line.price * line.qty |number }}
                                        </ng-template>
                                        <ng-template #showloaderMobile>
                                            <i nz-icon [nzType]="'loading'"></i>
                                        </ng-template>
                                    </td>
                                    <td>
                                        <button *ngIf="line.removeable" nz-button class=" btn-remove" nz-tooltip nzTooltipTitle="Remove this Item from the quote" (click)="removeItem(line)">
                                            <i nz-icon nzType="close" nzTheme="outline"></i>
                                        </button>
                                    </td>
                                    <td></td>
                                </tr>
                                <!-- <tr *ngFor="let exItem of filterCashback(line)" style="font-size:12px ;">
                                    <td>{{exItem.description | titlecase}}</td>
                                    <td>{{exItem.price |number }}</td>
                                    <td>{{exItem.qty}}</td>
                                    <td>
                                        <ng-container *ngIf="this.posquote.custId == 14041 || this.posquote.custId == 0 then showloaderMobileService; else showLinePriceMobileService">
                                        </ng-container>
                                        <ng-template #showLinePriceMobileService>
                                            {{exItem.price * exItem.qty |number }}
                                        </ng-template>
                                        <ng-template #showloaderMobileService>
                                            <i nz-icon [nzType]="'loading'"></i>
                                        </ng-template>
                                    </td>
                                    <td>
                                        <button *ngIf="exItem.removeable" nz-button class=" btn-remove" nz-tooltip nzTooltipTitle="Remove this Item from the quote" (click)="removeItem(exItem)">
                                    <i nz-icon nzType="close" nzTheme="outline"></i>
                                  </button>
                                    </td>
                                </tr> -->
                            </tbody>
                        </nz-table>
                    </div>
                </div>
                <br>
                <div nz-row nzJustify="center">
                    <div nz-col nzSpan="12" class="total">
                        <ng-container *ngIf="this.posquote.custId == 14041 || this.posquote.custId == 0 then showloaderMobileService; else showLinePriceMobileService">
                        </ng-container>
                        <ng-template #showLinePriceMobileService>
                            Total <b class="sfText">R {{calcTotal(optionId) |number }}</b>
                            <!-- Total <b class="sfText">R {{calcOtherTotal(line) + line.price * line.qty |number }}</b> -->
                        </ng-template>
                        <ng-template #showloaderMobileService>
                            <i nz-icon [nzType]="'loading'"></i>
                        </ng-template>
                    </div>
                    <div nz-col nzSpan="12" class="total">
                        <button nz-button [class]="'btnBack'" class="btn-mobile" (click)="acceptOption(optionId)">
                        Select Option
                    </button>
                    </div>
                </div>
            </nz-card>
            <nz-card *ngIf="tyreLines.length == 0" class="sfText" style="margin-bottom: 25px;" nzBordered="false" nzTitle="Service Quote">
                <div nz-row nzJustify="center">
                    <div nz-col nzSpan="24">
                        <nz-table [nzLoading]="isLoading" [nzOuterBordered]="true" nzSize="small" [nzData]="otherLines" [nzHideOnSinglePage]="true">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Price</th>
                                    <th>Qty</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let exItem of  otherLines" style="font-size:12px ;">
                                    <td>{{exItem.description | titlecase}}</td>
                                    <td>{{exItem.price |number }}</td>
                                    <td>{{exItem.qty}}</td>
                                    <td>{{exItem.price * exItem.qty |number }}</td>
                                    <td>
                                        <button *ngIf="exItem.removeable" nz-button class="btnremove" nz-tooltip nzTooltipTitle="Click to Remove Item" (click)="removeItem(exItem)">
                                    <i nz-icon nzType="close" nzTheme="outline"></i>
                                </button>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                </div>
                <br>
                <div nz-row nzJustify="center">
                    <div nz-col nzSpan="12">
                        <ng-container *ngIf="this.posquote.custId == 14041 || this.posquote.custId == 0 then showloaderTotal; else showTotal">
                        </ng-container>
                        <ng-template #showTotal>
                            Total <b class="sfText">R {{this.otherTotal |number }} </b>
                        </ng-template>
                        <ng-template #showloaderTotal>
                            <i nz-icon [nzType]="'loading'"></i>
                        </ng-template>
                    </div>
                    <div nz-col nzSpan="12" class="total">
                        <button nz-button [class]="'btnBack'" (click)="acceptServiceQuote()" class="btn-mobile">
                        Select Option
                    </button>
                    </div>
                </div>
            </nz-card>
        </ng-template>

        <ng-template #Other>
            <div nz-row>
                <div nz-col nzSpan="24">
                    <nz-tabset nzCentered nzSize="small">
                        <nz-tab *ngFor="let optionId of filteredLines(); let i = index" nzTitle="Option {{i + 1}}">
                            <div nz-row nzJustify="center">
                                <div nz-col nzSpan="24">
                                    <nz-table [nzLoading]="isLoading" [nzOuterBordered]="false" nzSize="small" [nzPageSize]="100" #filteredOption [nzData]="filteredItemsForOption(optionId)" [nzHideOnSinglePage]="true">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Brand</th>
                                                <th>Description</th>
                                                <th>Price</th>
                                                <th>Qty</th>
                                                <th>Total</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let line of filteredOption.data">
                                            <tr>
                                                <td><img *ngIf="line.brand" src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{line.brandLogo}}" class="brandImage"></td>
                                                <td>
                                                    {{line.brand}}
                                                </td>
                                                <td>{{line.description}}</td>
                                                <td>{{line.price |number }}</td>
                                                <td>
                                                    <nz-input-number class="input-number " [ngModel]="line.qty" [nzMin]="1" [nzMax]="500" [nzStep]="1" (ngModelChange)="updateLineItem(i,$event)">
                                                    </nz-input-number>
                                                </td>
                                                <td>
                                                    <ng-container *ngIf="this.posquote.custId == 14041 || this.posquote.custId == 0 then showloader; else showLinePrice">
                                                    </ng-container>
                                                    <ng-template #showLinePrice>
                                                        {{line.price * line.qty |number }}
                                                    </ng-template>
                                                    <ng-template #showloader>
                                                        <i nz-icon [nzType]="'loading'"></i>
                                                    </ng-template>
                                                </td>
                                                <td>
                                                    <button *ngIf="line.removeable" nz-button class=" btn-remove" nz-tooltip nzTooltipTitle="Remove this Item from the quote" (click)="removeItem(line)">
                                                        <i nz-icon nzType="close" nzTheme="outline"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <!-- <tr *ngFor="let exItem of filterCashback(line)">
                                                <td></td>
                                                <td>
                                                    {{exItem.brand}}
                                                </td>
                                                <td>{{exItem.description | titlecase}}</td>
                                                <td>{{exItem.price |number }}</td>
                                                <td>{{exItem.qty}}</td>
                                                <td>
                                                    <ng-container *ngIf="this.posquote.custId == 14041 || this.posquote.custId == 0 then showloaderServices; else showLinePriceServices">
                                                    </ng-container>
                                                    <ng-template #showLinePriceServices>
                                                        {{exItem.price * exItem.qty |number }}
                                                    </ng-template>
                                                    <ng-template #showloaderServices>
                                                        <i nz-icon [nzType]="'loading'"></i>
                                                    </ng-template>
                                                </td>
                                                <td>
                                                    <button *ngIf="exItem.removeable" nz-button class=" btn-remove" nz-tooltip nzTooltipTitle="Remove this Item from the quote" (click)="removeItem(exItem)">
                                                        <i nz-icon nzType="close" nzTheme="outline"></i>
                                                    </button>
                                                </td>
                                            </tr> -->

                                        </tbody>
                                    </nz-table>
                                </div>
                            </div>
                            <br>
                            <div nz-row [nzJustify]="'center'">
                                <div nz-col nzSpan="12" class="total">
                                    <ng-container *ngIf="this.posquote.custId == 14041 || this.posquote.custId == 0 then loaderOriginal; else TotalOriginal">
                                    </ng-container>
                                    <ng-template #TotalOriginal>
                                        Total <b class="sfText">R {{ calcTotal(optionId) |number }}</b>
                                        <!-- Total <b class="sfText">R {{ calcOtherTotal(line) + line.price * line.qty |number }}</b> -->
                                    </ng-template>
                                    <ng-template #loaderOriginal>
                                        Total <b class="sfText">R <i nz-icon [nzType]="'loading'"></i></b>
                                    </ng-template>
                                </div>
                                <div nz-col nzSpan="12" class="total" *ngIf="!hide">
                                    <button nz-button [class]="'btnBack'" (click)="acceptOption(optionId)" [class]="'btnBack'" class="btn-mobile">
                                        <!--*ngIf="!this.isSalesman"-->
                                        Select Option
                                    </button>
                                </div>
                            </div>
                        </nz-tab>
                        <nz-tab *ngIf="tyreLines.length == 0" nzTitle="Service Quote">
                            <div nz-row nzJustify="center">
                                <div nz-col nzSpan="24">
                                    <nz-table [nzLoading]="isLoading" [nzOuterBordered]="false" nzSize="small" [nzPageSize]="100" [nzData]="otherLines" [nzHideOnSinglePage]="true">
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Price</th>
                                                <th>Qty</th>
                                                <th>Total</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let exItem of otherLines">
                                                <td>{{exItem.description | titlecase}}</td>
                                                <td>{{exItem.price |number }}</td>
                                                <td>{{exItem.qty}}</td>
                                                <td>
                                                    <ng-container *ngIf="this.posquote.custId == 14041 || this.posquote.custId == 0 then showloaderService; else showLinePriceService">
                                                    </ng-container>
                                                    <ng-template #showLinePriceService>
                                                        {{exItem.price * exItem.qty |number }}
                                                    </ng-template>
                                                    <ng-template #showloaderService>
                                                        <i nz-icon [nzType]="'loading'"></i>
                                                    </ng-template>
                                                </td>
                                                <td>
                                                    <button *ngIf="exItem.removeable" nz-button class="btnremove" nz-tooltip nzTooltipTitle="Click to Remove Item" (click)="removeItem(exItem)">
                                                <i nz-icon nzType="close" nzTheme="outline"></i>
                                            </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </nz-table>
                                </div>
                            </div>
                            <br>
                            <div nz-row nzJustify="center">
                                <div nz-col nzSpan="12" class="total">
                                    <ng-container *ngIf="this.posquote.custId == 14041 || this.posquote.custId == 0 then showloaderOriginal; else showTotalOriginal">
                                    </ng-container>
                                    <ng-template #showTotalOriginal>
                                        Total R <b>{{this.otherTotal |number }} </b>
                                    </ng-template>
                                    <ng-template #showloaderOriginal>
                                        <i nz-icon [nzType]="'loading'"></i>
                                    </ng-template>
                                </div>
                                <div nz-col *ngIf="!hide" nzSpan="12">
                                    <button nz-button [class]="'btnBack'" class="btn-mobile" (click)="acceptServiceQuote()">
                                    <!-- *ngIf="!this.isSalesman" -->
                                    Accept Quote
                                  </button>
                                </div>
                            </div>
                        </nz-tab>
                    </nz-tabset>
                </div>
            </div>
        </ng-template>

        <br>

        <div nz-row nzJustify="center">
            <div nz-col>
                <h5>Once you select an option you can change the quantity of tyres and make a booking for extra fast service.</h5>
            </div>
        </div>


        <div nz-row nzJustify="center">
            <div>
                <h5 style="color: black;">*All Prices Vat(Incl) in <span style="color:var(--primaryColor)"><b>ZAR</b></span></h5>
            </div>
        </div>


        <br>

        <hr class="hr ">

        <br>

        <div nz-row nzJustify="center">
            <div nz-col>
                <p style="font-weight: bold;" class="sfText">{{ posquote.sellerName }}</p>
            </div>
        </div>

        <div nz-row nzJustify="center" *ngIf="posquote.sellerAddress != null">
            <div nz-col>
                <p> {{ posquote.sellerAddress }}</p>
            </div>
        </div>

        <div nz-row nzJustify="center">
            <div nz-col>
                <b> {{ posquote.sellerCell }}</b>
            </div>
        </div>

        <br>

        <div nz-row nzJustify="center">
            <div nz-col nzSpan="12" *ngIf="posquote.sellerName != posquote.user.userName ">
                <div *ngIf="posquote.user">
                    <b class="sfText">From:</b> {{posquote.user.userName}}<br/>
                    <span>{{posquote.user.userCell }}</span>
                </div>
            </div>

            <div nz-col nzSpan="12" *ngIf="posquote.custName !=''">
                <b class="sfText">To:</b> {{posquote.custName}} <br /> {{posquote.custCell}}
            </div>
        </div>

        <br>

        <div nz-row nzJustify="center" *ngIf="posquote.showBankingDetails">
            <div nz-col nzSpan="6" class="text-align">
                <b class="sfText">Banking Details:</b>
                <div style="word-wrap: break-all;">
                    <b>Beneficiary :</b> {{posquote.beneficiaryName}}<br/>
                    <b>Bank :</b> {{posquote.bankName}}<br/>
                    <b>Account Type:</b> {{posquote.accountType}}<br/>
                    <b>Branch Code :</b> {{posquote.branchCode}}<br/>
                    <b>Account Number :</b> {{posquote.accountNumber}}<br/>
                </div>
            </div>
        </div>

        <br>

        <div nz-row nzJustify="center">
            <div nz-col>
                <b class="sfText">Ref:</b> {{posquote.quoteRef}}
                <b class="sfText">Date:</b> {{posquote.dateQuoted | slice:0:10 }}
            </div>
        </div>


        <br>


        <div nz-row nzJustify="center">
            <div nz-col nzSpan="12">
                <button nz-button (click)="downloadPDF()">
                   <i nzType="left"  nz-icon nzType="download" nzTheme="outline"></i>
                </button><br> Download Quote
            </div>
            <div nz-col nzSpan="12" *ngIf="!this.isMobile">
                <button nz-button (click)="printPDF()">
                    <i nzType="center"  nz-icon nzType="printer" nzTheme="outline"></i>
                </button>
                <br> Print Quote
            </div>
        </div>

        <br>
        <br>


        <!-- <div #pdfElement *ngIf="!isLoading" class="pdfElement" style="display: none;">
            <div nz-row nzJustify="center">
                <div nz-col nzSpan="12">
                    <span style="font-weight: bold;" class="sfText">QUOTATION</span><br />
                    <b>Ref:</b> {{posquote.quoteRef}}<br />
                    <b>Date:</b> {{posquote.dateQuoted}}
                    <br /><br />
                    <b>To:</b> {{posquote.custName}} <br /> {{posquote.custEmail}}
                    <br /> {{posquote.custCell}}
                </div>
                <div nz-col nzSpan="12">
                    <span style="font-weight: bold;" class="sfText">DEALER</span><br /> {{ posquote.sellerName }} <br /> {{ posquote.sellerEmail }} <br />
                    <b>{{ posquote.sellerCell }}  </b> <br />
                    <div *ngIf="posquote.sellerBankingDetails" style="white-space:pre-wrap;">{{posquote.sellerBankingDetails}}</div>
                    <div *ngIf="posquote.user">
                        <span style="font-weight: bold;" class="sfText">Salesman</span><br /> {{posquote.user.userName}}
                        <br/>
                        <b>{{posquote.user.userCell }} </b>
                    </div>
                </div>
            </div>
            <hr>
            <div nz-row nzJustify="center">
                <div nz-col nzSpan="24">
                    <div *ngFor="let line of tyreLines; let i = index" style="page-break-inside: avoid;">
                        <b>OPTION {{i + 1}}</b>
                        <table>
                            <thead>
                                <tr style="background-color: black; color: white; border: 1px solid black;">
                                    <td style="width: 80px"></td>
                                    <td style="width: 125px">BRAND</td>
                                    <td style="width: 325px">DESCRIPTION</td>
                                    <td style="width: 60px">PRICE</td>
                                    <td style="width: 50px">QTY</td>
                                    <td style="width: 65px">TOTAL</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="border: 1px solid grey;">
                                    <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{line.brandLogo}}" class="brandImage"></td>
                                    <td>
                                        {{line.brand}}
                                    </td>
                                    <td>{{line.description}}</td>
                                    <td>R {{line.price}}</td>
                                    <td>{{line.qty}}</td>
                                    <td>R {{line.price * line.qty}}</td>
                                </tr>
                                <tr style="border: 1px solid grey" *ngFor="let exItem of filterCashback(line)">
                                    <td></td>
                                    <td>
                                        {{exItem.brand}}
                                    </td>
                                    <td>{{exItem.description | titlecase}}</td>
                                    <td>R {{exItem.price}}</td>
                                    <td>{{exItem.qty}}</td>
                                    <td>R {{exItem.price * exItem.qty}}</td>
                                </tr>
                                <tr>
                                    <td colspan="5" style="text-align: right;padding-right: 20px;">
                                        <b>TOTAL</b>
                                    </td>
                                    <td>
                                        <b>R {{calcOtherTotal(line) + line.price * line.qty}}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->

        <!------------------------------->
    </div>
</div>


<nz-modal [nzMaskClosable]="false" [(nzVisible)]="showAddDetails" nzClosable="false" nzTitle="Please Enter Your Contact Details To Continue" (nzOnCancel)="showAddDetails = false;" (nzOnOk)="AddCustomer()">
    <div *nzModalContent>
        <form [formGroup]="customerForm" class="text-center">
            <nz-form-item>
                <nz-form-control [nzErrorTip]="'Cell Number required'">
                    <nz-input-group nzPrefixIcon="phone" class="solid">
                        <input class="inputdiv" (keypress)="quoteService.numberInput($event)" maxlength="10" nz-input name="cell" formControlName="cell" placeholder="Cell Number">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control [nzErrorTip]="'Name required'">
                    <nz-input-group nzPrefixIcon="user" class="solid">
                        <input class="inputdiv" nz-input name="name" formControlName="name" placeholder="First Name">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <small style="color: var(--primaryColor)">*Optional For email Confirmations*</small>
                    <nz-input-group nzPrefixIcon="mail" class="solid">
                        <input class="inputdiv" nz-input name="email" formControlName="email" placeholder="Email Address">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button class="btn-mobile btnBack" type="submit" (click)="AddCustomer()">
            Save
        </button>
    </div>
</nz-modal>